import React from 'react';
// import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Button } from 'gatsby-theme-material-ui';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
// import Accordion from '@mui/material/Accordion';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} round {...props} />
))(({ theme }) => ({
  //   border: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  //   '&:not(:last-child)': {
  //     borderBottom: 0,
  //   },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#F9F9F9',
  //   theme.palette.mode === 'dark'
  //     ? 'rgba(255, 255, 255, .05)'
  //     : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    color: '#FFA100',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function FAQsSection() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <section id="FAQ-microREC">
      <Box
        sx={{
          backgroundColor: '#F9F9F9',
          paddingTop: 10,
          paddingBottom: 10,
          marginTop: 1,
        }}
      >
        <Container maxWidth={'lg'}>
          <Grid
            container
            spacing={2}
            // sx={{
            //   backgroundColor: '#F9F9F9',
            //   paddingTop: 10,
            //   paddingBottom: 10,
            //   marginTop: 1,
            // }}
            maxWidth={'100%'}
            justifyContent="space-evenly"
          >
            <Grid item xs={12} md={4}>
              <Typography
                variant={'h5'}
                color={'primary'}
                sx={{ padding: 2, fontWeight: 'bold' }}
              >
                Frequently Asked Questions
              </Typography>
            </Grid>
            <Grid item xs={12} md={7}>
              <Accordion
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
              >
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography sx={{ flexShrink: 1 }}>
                    Is MicroREC KIT compatible with my microscope model?
                  </Typography>
                  {/* <Typography sx={{ color: 'text.secondary' }}>
              Filtering has been entirely disabled for whole web server
            </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ padding: 1 }}>
                    We offer modular adapters that connect to {'>90%'} of
                    microscopic equipment on the market. You can check the full
                    list of compatible microscopes below and the corresponding
                    MicroREC KIT:
                  </Typography>
                  <Grid container>
                    <Grid
                      item
                      container
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Grid item xs={12} sm={4}>
                        <Typography sx={{ padding: 1, fontWeight: 'bold' }}>
                          MicroREC Kit for Leica
                        </Typography>
                      </Grid>
                      <Divider
                        orientation="vertical"
                        flexItem
                        display={{ xs: 'none', sm: 'flex' }}
                      />
                      <Grid item xs={12} sm={7}>
                        <Typography sx={{ padding: 1 }}>
                          <ul>
                            <li style={{ marginBottom: '1em' }}>
                              Leica: M320, M500, M501, M561, M220, M620, M822,
                              M844
                            </li>
                          </ul>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider style={{ width: '100%' }} />
                    <Grid
                      item
                      container
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Grid item xs={12} sm={4}>
                        <Typography sx={{ padding: 1, fontWeight: 'bold' }}>
                          MicroREC Kit for Zeiss
                        </Typography>
                      </Grid>
                      <Divider
                        orientation="vertical"
                        flexItem
                        display={{ xs: 'none', sm: 'flex' }}
                      />
                      <Grid item xs={12} sm={7}>
                        <Typography sx={{ padding: 1 }}>
                          <ul>
                            <li style={{ marginBottom: '1em' }}>
                              Zeiss: Lumera i, Lumera T, Lumera 700, Artevo 800,
                              OPMI pico, OPMI visu 210
                            </li>
                            <li style={{ marginBottom: '1em' }}>
                              TOPCON: OMS-800
                            </li>
                            <li style={{ marginBottom: '1em' }}>
                              Takagi: OM-6, OM-9, OM-19
                            </li>
                            <li style={{ marginBottom: '1em' }}>
                              Zumax: OMS3200, OMS2360, OMS2380, OMS2350
                            </li>
                            <li style={{ marginBottom: '1em' }}>
                              Opto DM: PRO IB
                            </li>
                            <li style={{ marginBottom: '1em' }}>
                              Altion: AM-4000
                            </li>
                            <li style={{ marginBottom: '1em' }}>
                              CJ: Optik Flexion
                            </li>
                            <li style={{ marginBottom: '1em' }}>
                              Ecleris: OM-100
                            </li>
                          </ul>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider style={{ width: '100%' }} />
                    <Grid
                      item
                      container
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Grid item xs={12} sm={4}>
                        <Typography sx={{ padding: 1, fontWeight: 'bold' }}>
                          MicroREC Kit for Möller-Wedel/Haag-Streit Surgical
                        </Typography>
                      </Grid>
                      <Divider
                        orientation="vertical"
                        flexItem
                        display={{ xs: 'none', sm: 'flex' }}
                      />
                      <Grid item xs={12} sm={7}>
                        <Typography sx={{ padding: 1 }}>
                          <ul>
                            <li style={{ marginBottom: '1em' }}>
                              Möller-Wedel / Haag-Streit Surgical: Allegra 90,
                              Allegra 900, HI-R NEO 900
                            </li>
                          </ul>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider style={{ width: '100%' }} />
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel3_1'}
                onChange={handleChange('panel3_1')}
              >
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography sx={{ flexShrink: 1 }}>
                    Is MicroREC KIT compatible with my slit lamp model?
                  </Typography>
                  {/* <Typography sx={{ color: 'text.secondary' }}>
              Filtering has been entirely disabled for whole web server
            </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ padding: 1 }}>
                    We offer modular adapters that connect to {'>90%'} of
                    ophthalmic slit lamps on the market. You can check the full
                    list of compatible slit lamps below and the corresponding
                    MicroREC KIT:
                  </Typography>
                  <Grid container>
                    <Grid
                      item
                      container
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Grid item xs={12} sm={4}>
                        <Typography sx={{ padding: 1, fontWeight: 'bold' }}>
                          MicroREC Kit for Zeiss
                        </Typography>
                      </Grid>
                      <Divider
                        orientation="vertical"
                        flexItem
                        display={{ xs: 'none', sm: 'flex' }}
                      />
                      <Grid item xs={12} sm={7}>
                        <Typography sx={{ padding: 1 }}>
                          <ul>
                            <li style={{ marginBottom: '1em' }}>
                              Zeiss: 100/16, 20SL, 30SL
                            </li>
                            <li style={{ marginBottom: '1em' }}>
                              Topcon: SL-D701, SL-D4, SL-D2, SL-D4Z, SL-D5,
                              SL-D301, SL-2G, SL-D7, SL-2F, SL-7F, SL-2ED,
                              SL-7E, SL-8Z, Pascal Laser
                            </li>
                            <li style={{ marginBottom: '1em' }}>
                              NIDEK: SL1800, SL 1600, SL 2000
                            </li>
                            <li style={{ marginBottom: '1em' }}>
                              {' '}
                              MARCO: Ultra G2, Ultra G4, Ultra G5, Ultra
                              G5-Zoom, Ultra M2, Ultra M4, Ultra M5, Ultra B2
                            </li>
                            <li style={{ marginBottom: '1em' }}>
                              WOODLYN: HR3, HR5
                            </li>
                            <li style={{ marginBottom: '1em' }}> ELAN: 3, 5</li>
                            <li style={{ marginBottom: '1em' }}>
                              {' '}
                              BURTON: SL-2000, SL-3MH, SL-3MZ, SL-860
                            </li>
                            <li style={{ marginBottom: '1em' }}>
                              S4OPTIK: SL-H3, SL-H5, SL-HZ, SL-Z3, SL-Z5, SL-ZZ,
                              H5 Elite
                            </li>
                            <li style={{ marginBottom: '1em' }}>
                              TAKAGI: SM-70N, SM-2, SM-4, SM-90
                            </li>
                            <li style={{ marginBottom: '1em' }}>
                              INAMI: Classicus L-0179 / L-0179 Z, Classicus
                              L-0219, L-0187 / L-0240, L-0189 / L-0229, L-0229-E
                            </li>
                            <li style={{ marginBottom: '1em' }}>
                              ARC LASER: CLASSIC 532, 514
                            </li>
                          </ul>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider style={{ width: '100%' }} />
                    <Grid
                      item
                      container
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Grid item xs={12} sm={4}>
                        <Typography sx={{ padding: 1, fontWeight: 'bold' }}>
                          MicroREC Kit for&nbsp;Haag-&nbsp;Streit
                        </Typography>
                      </Grid>
                      <Divider
                        orientation="vertical"
                        flexItem
                        display={{ xs: 'none', sm: 'flex' }}
                      />
                      <Grid item xs={12} sm={7}>
                        <Typography sx={{ padding: 1 }}>
                          <ul>
                            <li style={{ marginBottom: '1em' }}>
                              Haag-Streit Surgical: BQ 900
                            </li>
                          </ul>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider style={{ width: '100%' }} />
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 'panel4'}
                onChange={handleChange('panel4')}
              >
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography sx={{ flexShrink: 1 }}>
                    What is included with the MicroREC KIT?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ padding: 1 }}>
                    A MicroREC kit consists of:
                    <ul>
                      <li style={{ marginBottom: '1em' }}>
                        <span>Adapter</span>{' '}
                      </li>
                      <li style={{ marginBottom: '1em' }}>
                        <span>Eyepiece</span>{' '}
                      </li>
                      <li style={{ marginBottom: '1em' }}>
                        <span>Universal MicroREC Tube</span>{' '}
                      </li>
                      <li style={{ marginBottom: '1em' }}>
                        <span>Universal Smartphone Holder</span>{' '}
                      </li>
                      {/* <li style={{ marginBottom: '1em' }}>
                        <span style={{ color: '#8F4FFF' }}>
                          MicroREC Briefcase
                        </span>{' '}
                      </li> */}
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
              >
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ flexShrink: 1 }}>
                    What are the use cases of the MicroREC?
                  </Typography>
                  {/* <Typography sx={{ color: 'text.secondary' }}>
            </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography gutterBottom={true} sx={{ padding: 1 }}>
                    The MicroREC allows to enjoy the full potential of your
                    microscope or slit lamp. Our unique optical system will
                    allow you to:
                    <ul>
                      <li style={{ marginBottom: '1em' }}>
                        <span style={{ color: '#8F4FFF', fontWeight: 'bold' }}>
                          Record
                        </span>{' '}
                        <br />
                        Capture the best quality surgical recordings - without
                        even having to lift your eyes from the oculars
                      </li>
                      <li style={{ marginBottom: '1em' }}>
                        <span style={{ color: '#8F4FFF', fontWeight: 'bold' }}>
                          Broadcast
                        </span>{' '}
                        <br />
                        Seamless live sharing to other screens - so that anyone
                        you want can see your procedure at any time
                      </li>
                      <li style={{ marginBottom: '1em' }}>
                        <span style={{ color: '#8F4FFF', fontWeight: 'bold' }}>
                          Train
                        </span>{' '}
                        <br />
                        Reviewing your procedures or showing them to your
                        students, will skyrocket your and their surgical skills{' '}
                      </li>
                      <li style={{ marginBottom: '1em' }}>
                        <span style={{ color: '#8F4FFF', fontWeight: 'bold' }}>
                          Share
                        </span>{' '}
                        <br />
                        Easily share your procedures with your collegues or in
                        social media - with just one click
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
              >
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography sx={{ flexShrink: 1 }}>
                    How to install MicroREC?
                  </Typography>
                  {/* <Typography sx={{ color: 'text.secondary' }}>
              You are currently not an owner
            </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ padding: 1 }}>
                    Our installation process is pretty easy and doesn't take
                    much time.
                  </Typography>
                  <Typography sx={{ padding: 1 }}>
                    Please watch this video to install a MicroREC if your
                    microscope has a beam splitter:
                  </Typography>
                  <div
                    style={{
                      justifyContent: 'center',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <a
                      href="https://youtu.be/vNvsgYZyyr0"
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: 'none' }}
                    >
                      <Button color="primary" variant="contained">
                        {' '}
                        Install MicroREC{' '}
                      </Button>
                    </a>
                  </div>
                  <Typography sx={{ padding: 2 }}>
                    And this one if you ordered a MicroREC kit with our beam
                    splitter.
                  </Typography>
                  <div
                    style={{
                      justifyContent: 'center',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <a
                      href="https://youtu.be/tW7s5qFnf20"
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: 'none' }}
                    >
                      <Button color="primary" variant="contained">
                        {' '}
                        Install MicroREC with Beam Splitter{' '}
                      </Button>
                    </a>
                  </div>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </section>
  );
}
