import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Compatible from '../assets/benefits/android-ios-compatible.svg';
import MoneyBack from '../assets/benefits/moneyback.svg';
import Warranty from '../assets/benefits/warranty.svg';

const BenefitsSectionMicroREC = () => {
  return (
    // <Paper
    //   sx={{
    //     p: 2,
    //     margin: 'auto',
    //     flexGrow: 1,
    //     backgroundColor: 'F9F9F9',
    //   }}
    // >
    // <Container
    //   sx={{ backgroundColor: '#F9F9F9', paddingTop: 5, paddingBottom: 5 }}
    //   maxWidth={'100%'}
    // >
    <Box
      sx={{
        backgroundColor: '#F9F9F9',
        paddingTop: 5,
        paddingBottom: 5,
      }}
    >
      <Container
        maxWidth={'lg'}
        margin={0}
        // maxWidth={'100%'}
        justifyContent={'space-around'}
      >
        <Grid
          container
          spacing={4}
          justifyContent={'space-around'}
          alignItems={'center'}
        >
          <Grid
            container
            item
            // width={'25%'}
            textAlign={'center'}
            // alignSelf={'flex-start'}
            // justifyContent={'space-around'}
            xs={5}
            sm
          >
            <Grid item pb={2} xs={12}>
              <MoneyBack />
            </Grid>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                variant="subtitle1"
                component="div"
                textAlign={'center'}
              >
                14-day money back guaranteed
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            // item
            // width={'25%'}
            textAlign={'center'}
            // alignSelf={'flex-start'}
            xs={5}
            sm
          >
            <Grid item pb={2} xs={12}>
              <Warranty />
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom variant="subtitle1" component="div">
                2 year warranty
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            // item
            // width={'25%'}
            textAlign={'center'}
            // alignSelf={'flex-start'}
            xs={5}
            sm
          >
            <Grid item pb={2} xs={12}>
              <Compatible />
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom variant="subtitle1" component="div">
                iOS and Android compatible
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default BenefitsSectionMicroREC;
