import React from 'react';
// import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const QuoteSection = () => {
  return (
    // <Paper
    //   sx={{
    //     p: 2,
    //     margin: 'auto',
    //     flexGrow: 1,
    //     backgroundColor: 'F9F9F9',
    //   }}
    // >
    <Box sx={{ backgroundColor: '#8F4FFF', paddingTop: 10, paddingBottom: 10 }}>
      <Container maxWidth={'lg'}>
        <Box
          padding={{ xs: 5, sm: 10 }}
          marginLeft={{ xs: 0, sm: 15 }}
          marginRight={{ xs: 0, sm: 15 }}
          sx={{
            backgroundColor: '#F9F9F9',
            borderRadius: '20px',
          }}
        >
          <Typography
            variant="h5"
            // align="center"
            sx={{ fontWeight: 'bold', marginBottom: 2 }}
            gutterBottom={true}
          >
            Democratizing the ability to capture, share, store and&nbsp;learn
          </Typography>
          <Typography
            variant="body1"
            // align="center"
            gutterBottom={true}
            sx={{ marginBottom: 2, textAlign: 'justify' }}
          >
            'As a surgeon, I believe image and video capturing is an essential
            part of growing. Most available systems are expensive, difficult to
            use and cannot be adapted to many systems. MicroREC solves this in a
            universal and cost effective way.',
          </Typography>

          <Typography
            variant="body1"
            // align="center"
            gutterBottom={true}
          >
            <span style={{ fontWeight: 'bold' }}>Dr. Ike Ahmed &emsp;</span>
            <span style={{ color: '#2EC4B6' }}>
              Ophthalmologist, Researcher and Innovator
            </span>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default QuoteSection;
