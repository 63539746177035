import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// import SectionHeading from '../components/section-heading';
import CompatibilityBox from '../components/compatibility-box';
// import compatibilityData from '../images/logos_compatibility/logos.data'
import { useStaticQuery, graphql } from 'gatsby';
// import ButtonSvgLink from '../components/arrow-button';

const CompatibilitySection = () => {
  const logosMicroscopeQuery = useStaticQuery(graphql`
    query {
      MicroscopesLogos: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativePath: { regex: "/(logos_compatibility/microscope)/" }
        }
        sort: { order: ASC, fields: relativePath }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 70)
            }
          }
        }
      }
      SlitLampsLogos: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativePath: { regex: "/(logos_compatibility/slitlamp)/" }
        }
        sort: { order: ASC, fields: relativePath }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 70)
            }
          }
        }
      }
    }
  `);

  return (
    <section id="compatibility" sx={styles.section}>
      <Box
        padding={{ xs: 2, md: 10 }}
        marginTop={{ xs: 1, md: 1 }}
        sx={{
          backgroundColor: '#F9F9F9',
        }}
      >
        <Container maxWidth={'lg'}>
          <Grid
            container
            // maxWidth="none"
            justifyContent={'space-evenly'}
            alignItems="center"
            // spacing={2}
            rowSpacing={{ xs: 3 }}
            // columnSpacing={{ md: 3 }}
          >
            <Grid item xs={11} sm={4}>
              <CompatibilityBox
                title="Microscopes"
                logosItems={logosMicroscopeQuery.MicroscopesLogos}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <CompatibilityBox
                title="Slit Lamps"
                logosItems={logosMicroscopeQuery.SlitLampsLogos}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </section>
  );
};

export default CompatibilitySection;

const styles = {
  section: {
    backgroundColor: 'rgba(242, 242, 242, 0.7)',
    pt: [9, null, null, null, 10, 13, null],
    pb: [9, null, null, null, 11, 14, null],
  },
  heading: {
    mb: [7, null, null, 8, 9, 10],
    h2: {
      color: 'text',
      fontSize: [5, null, null, 8],
      letterSpacing: ['1px'],
    },
    p: {
      color: '#858B91',
      fontSize: [2, null, null, 3],
      m: ['15px auto 0', null, null, '10px auto 0'],
      // maxWidth: '760px',
    },
  },
  containerSection: {
    flexDirection: ['column', null, null, 'row'],
    display: 'flex',
    gap: '30px',
    // justifyContent: 'stretch',
    // maxHeight: '450px',
  },
  others: {
    textAlign: 'center',
    color: '#858B91',
    fontSize: [2, null, null, 3],
    lineHeight: [1.86, null, 2.2],
    mt: [7],
    // maxWidth: 800,
  },
};
