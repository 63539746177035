import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import Image from './image'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const CompatibilityBox = ({ title, logosItems, ...props }) => {
  return (
    <Grid
      item
      sx={styles.mainBox}
      maxWidth={'600px'}
      padding={2}
      textAlign={'center'}
    >
      <Typography variant={'h5'} sx={{ fontWeight: 'bold', padding: '4px' }}>
        {' '}
        {title}{' '}
      </Typography>
      <Grid
        container
        sx={{
          justifyContent: 'space-around',
          padding: 2,
          alignItems: 'center',
        }}
      >
        {logosItems.edges.map(({ node }, i) => {
          const image = getImage(node);
          return (
            <Grid item justifyContent={'space-around'} margin="2%">
              <GatsbyImage image={image} key={node.name} fadeIn={false} loading="eager"/>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default CompatibilityBox;

const styles = {
  mainBox: {
    // display: 'block',
    borderRadius: '50px',
    backgroundColor: 'white',
    boxShadow: ' 0px 4px 30px 5px rgba(0, 0, 0, 0.25)',
    // textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'space-around',
    // flexShrink: '1',
    // maxWidth: '400px',
    // maxHeight: '250px',
    // width: 'calc(100% + 48px)',
    // flexWrap: 'wrap',
    // maxHeight: '400px',
    // gap: '10px',
    // width: '50%',
  },
  logosFlex: {
    // maxWidth: '400px',
    // maxHeight: '250px',
    flexFlow: 'row wrap',
    // flexDirection: 'row',
    // flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    // listStyle: "none",
    // width: 'calc(100% + 32px)',
    // flexWrap: 'wrap',
    // width: '400px',
    alignItems: 'center',
  },
  logo: {
    // margin: '0 16px 32px 16px',
    margin: '2%',

    //  Animation for B&W to Color with hovering
    // filter: 'grayscale(100%)',
    // '-webkit-filter': 'grayscale(100%)',
    // ':hover': {
    //     filter: 'none',
    //     '-webkit-filter': 'grayscale(0%)',
    //     '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
    //     // transform: ['scale(1.1,1.1)'],
    //     transition: '0.2s ease-in-out',
    //   },

    // justifyContent: 'center',
    // maxWidth:'100px',
    // li: {
    //     display: 'list-item',
    //     textAlign: '-webkit-match-parent',
    // }
  },
};
