import React, { useContext, useEffect } from 'react';
import { myContext } from '../utils/provider';

import { ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import Container from '@mui/material/Container';
// import cs_theme from '../gatsby-theme-material-ui-top-layout/theme'

import Layout from '../components/layout';
// import Image from "../components/image"
import SEO from '../components/seo';

import ProductSectionMicroREC from '../sections_microREC/product-section';
import BenefitsSectionMicroREC from '../sections_microREC/benefits';
import CompatibilitySection from '../sections_microREC/compatibility';
import QuoteSection from '../sections_microREC/quote';
import FAQsSection from '../sections_microREC/faqs';

import { CartProvider } from 'use-shopping-cart/react';

import useStripeProducts from '../components/products/useStripeProducts';
import CartModal from '../components/store/cartModal';

import '@fontsource/red-hat-display';
import '@fontsource/red-hat-display/700.css';

import { createTheme } from '@mui/material/styles';

let cs_theme = createTheme({
  palette: {
    primary: {
      main: '#FFA100',
    },
    secondary: {
      main: '#00C5B7',
    },
    white: '#FFFFFF',
    black: '#3D3838',
    purple: '#8F4FFF',
  },
  typography: {
    fontFamily: ['Red Hat Display'].join(','),
  },
});
cs_theme = responsiveFontSizes(cs_theme);

const IndexPage = () => {
  const products = useStripeProducts();

  const microrecAdapterKitsStripe = products.filter((product) => {
    return product.name.includes('MRC.') && !product.name.includes('.BS.');
  });

  const context = useContext(myContext);

  // useEffect(() => {
  //   if (typeof window !== 'undefined' && window.gtag) {
  //     console.log(window.gtag);
  //   }
  // }, []);

  const getCurrency = () => {
    if (context.country === 'US') {
      return 'USD';
    } else {
      return 'EUR';
    }
  };
  return (
    <ThemeProvider theme={cs_theme}>
      <CartProvider
        stripe={process.env.STRIPE_PUBLISHABLE_KEY}
        currency={getCurrency()}
      >
        <Container id="main_page" disableGutters="true" maxWidth={false}>
          <Layout helmetName="MicroREC">
            <SEO />

            <CartModal />

            <ProductSectionMicroREC
              microrecAdapterKitsStripe={microrecAdapterKitsStripe}
            />
            <BenefitsSectionMicroREC />
            <QuoteSection />
            <CompatibilitySection />
            <FAQsSection />
          </Layout>
        </Container>
      </CartProvider>
    </ThemeProvider>
  );
};

export default IndexPage;
