import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import CarouselMiniatures from '../components/carousel/carousel-with-miniatures';
import { useStaticQuery, graphql } from 'gatsby';
// import ProductCardSection from '../components/store/productCardSection';

import { formatCurrencyString } from 'use-shopping-cart';
import BrandSelect from '../components/brand-selection';

const ProductSectionMicroREC = ({ microrecAdapterKitsStripe, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      carrouselImagesMicroREC: allFile(
        filter: { relativePath: { regex: "/microREC/" } }
        sort: { fields: relativePath }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(width: 500, layout: CONSTRAINED)
            }
            name
          }
        }
      }
      brands2AdapterKit: allBrand2AdapterCompatibilityJson {
        nodes {
          Adapter
          Brand
          Device
          SKU
          Stripe_Product
          product_id
          kit_product_id
        }
      }
    }
  `);

  const brands2AdapterKit = data.brands2AdapterKit.nodes;
  const carrouselImagesMicroREC = data.carrouselImagesMicroREC;

  return (
    <section
      id="main_MicroREC_section"
      style={{ marginTop: 100, marginBottom: 100 }}
    >
      <Container maxWidth={'lg'}>
        <Grid container spacing={3} justifyContent={'center'}>
          <Grid item xs={10} sm={5}>
            <CarouselMiniatures data={carrouselImagesMicroREC.edges} />
          </Grid>
          <Grid
            container
            item
            xs={10}
            sm={6}
            marginTop={{ xs: 2, sm: 0 }}
            marginBottom={{ xs: 2, sm: 0 }}
            spacing={1}
            justifyContent={'space-between'}
          >
            {/* <Grid
              container
              spacing={1}
              justifyContent={'space-between'}
              order={{ xs: 1, sm: 2 }}
              // margin={{ xs: 2, sm: 0 }}
              marginTop={{ xs: 2, sm: 0 }}
              marginBottom={{ xs: 2, sm: 0 }}
            > */}
            <Grid item xs={8} sm={7}>
              <Typography
                variant="h4"
                align={'left'}
                gutterBottom={true}
                sx={{ fontWeight: 'bold' }}
              >
                MicroREC KIT
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4}>
              <Typography variant="h6" align={'center'} gutterBottom={true}>
                {formatCurrencyString({
                  value: microrecAdapterKitsStripe[0].price,
                  currency: microrecAdapterKitsStripe[0].currency,
                })}{' '}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                paragraph={true}
                gutterBottom={true}
                sx={{ textAlign: 'justify' }}
              >
                MicroREC is an optical system that digitalizes your microscope
                or slit lamp. <br />
                It allows seamless microscopy imaging recording and instant
                streaming or sharing of surgeries on your phone - without
                occupying the binoculars. <br />
                In addition, it comes with a dedicated iOS/Android app that
                changes how medical imaging is acquired and stored.
              </Typography>{' '}
              <Typography
                variant="subtitle2"
                paragraph={true}
                gutterBottom={true}
                sx={{ textAlign: 'justify' }}
              >
                * Beamsplitter is required for MicroREC attachment.
              </Typography>{' '}
            </Grid>
            {/* </Grid> */}

            {/* Dropdown to select the brand of the customer microscope or slitlamp */}
            <Grid item justifyContent={'center'}>
              <BrandSelect
                productName={'MicroREC KIT'}
                data={brands2AdapterKit}
                stripeProducts={microrecAdapterKitsStripe}
                page={'microREC-page'}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default ProductSectionMicroREC;
